import * as React from "react";
import Layout from "../components/Layout";
import { howItWorksFeatured } from "../assets/homepageData";
import SEO from "../components/SEO";
import heroimg from "../images/hero_get_listed.svg";
import { setItemToLStore } from "../helpers/hash";
import { navigate } from "gatsby";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const submitForm = (e) => {
  e.preventDefault();
  var formData = new FormData(e.currentTarget);

  let obj = { "form-name": "get-listed" };

  for (var value of formData.entries()) {
    obj[value[0]] = value[1];
    // myArray.push(obj);
  }

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: encode({
      ...obj,
      "landing-url":
        typeof window !== "undefined" &&
        window.localStorage &&
        window.localStorage.getItem("landingpath")
          ? window.localStorage.getItem("landingpath")
          : "Not Provided",
      "current-url":
        typeof window !== "undefined" &&
        window.localStorage &&
        window.localStorage.getItem("currentpath")
          ? window.localStorage.getItem("currentpath")
          : "Not Provided",
    }),
  })
    .then(() => {
      setItemToLStore("form", { date: new Date(), ...obj });
      //after submit success, show spinner .05 sec
      setTimeout(() => navigate("/thank-you/"), 500);
    })
    .catch((error) => alert(error));
};
const GetListed = () => {
  return (
    <Layout bg={"gray"} header="light">
      <SEO
        title={"Get Listed on LocalTier"}
        description={
          "Residents and Search Engines Alike Count on Blogs to Learn What’s Happening in Your Local Area! Get Listed Today."
        }
      />
      <div className="bg-gray-50 ">
        <div className="w-full max-w-4xl mx-auto py-3 md:py-6">
          <img src={heroimg} className="max-w-xs p-8 mx-auto -my-4" />

          <h1
            style={{ lineHeight: 1.1 }}
            className="text-center leading-7 max-w-2xl text-3xl md:text-4xl font-display font-extrabold text-gray-800 my-3  mx-auto"
          >
            Get Listed on{" "}
            <span className="tier-underline text-brand-600">LocalTier</span>
          </h1>
          <p className="text-center text-gray-600 leading-7 text-lg  max-w-3xl mx-auto px-4">
            Make sure your business is a part of the local action! Residents and
            search engines alike count on blogs to learn what’s happening in
            your local area! Get listed today.
          </p>

          <div className="max-w-4xl bg-white shadow-xl mx-auto p-8 md:p-12 rounded-xl my-8 text-left">
            <h3 className="text-lg md:text-xl font-bold text-gray-800 mb-3">
              Submit your business to LocalTier
            </h3>
            <p className="text-gray-600 leading-7 mb-2">
              <span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">
                1
              </span>{" "}
              Review &amp; complete the Get Listed form below.
            </p>
            <p className="text-gray-600 leading-7 mb-2">
              <span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">
                2
              </span>{" "}
              We will conduct our research and review your submission.
              <br />
            </p>
            <p className="text-gray-600 leading-7 mb-2">
              <span className="bg-gray-100 text-gray-900 p-1 rounded-full w-6 h-6 inline-flex items-center justify-center text-sm font-semibold mr-1">
                3
              </span>{" "}
              If approved, we will publish your listing and send you a
              notification.
            </p>

            <div className="my-10">
              <h3 className="text-lg md:text-xl font-bold text-gray-800">
                We Just Need a Few Details
              </h3>

              <form
                name="get-listed"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={(e) => submitForm(e)}
                data-netlify={true}
              >
                <div className="pt-4">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Personal Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Use a current email address where you can be notified.
                    </p>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="first-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        First name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="first-name"
                          id="first-name"
                          autoComplete="given-name"
                          required={true}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="last-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="last-name"
                          id="last-name"
                          autoComplete="family-name"
                          required={true}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="email"
                          autoComplete="email"
                          required={true}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pt-8">
                  <div>
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Business Information
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Use an address where you can receive mail.
                    </p>
                  </div>

                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="business-name"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business name
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="business-name"
                          id="business-name"
                          placeholder="Top Tier Business, Inc"
                          required={true}
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-4">
                      <label
                        htmlFor="business-desc"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business description
                      </label>
                      <div className="mt-1">
                        <textarea
                          type="text"
                          rows="5"
                          name="business-desc"
                          id="business-desc"
                          placeholder="Tell us a bit about your business"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="business-link"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Website Link
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="business-link"
                          id="business-link"
                          placeholder="www.localtier.com"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                    <div className="sm:col-span-3">
                      <label
                        htmlFor="business-phone"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Business Phone Number
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="business-phone"
                          id="business-phone"
                          placeholder="xxx-xxx-xxxx"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-3">
                      <label
                        htmlFor="country"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Country
                      </label>
                      <div className="mt-1">
                        <select
                          id="country"
                          name="country"
                          autoComplete="country-name"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        >
                          <option>United States</option>
                        </select>
                      </div>
                    </div>

                    <div className="sm:col-span-6">
                      <label
                        htmlFor="street-address"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Street address
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="street-address"
                          id="street-address"
                          autoComplete="street-address"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="city"
                        className="block text-sm font-medium text-gray-700"
                      >
                        City
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="city"
                          id="city"
                          autoComplete="address-level2"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="region"
                        className="block text-sm font-medium text-gray-700"
                      >
                        State / Province
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="region"
                          id="region"
                          autoComplete="address-level1"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>

                    <div className="sm:col-span-2">
                      <label
                        htmlFor="postal-code"
                        className="block text-sm font-medium text-gray-700"
                      >
                        ZIP / Postal code
                      </label>
                      <div className="mt-1">
                        <input
                          type="text"
                          name="postal-code"
                          id="postal-code"
                          autoComplete="postal-code"
                          className="shadow-sm focus:ring-brand-500 focus:border-brand-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  className="px-6 py-3 bg-brand-600 text-white rounded-xl font-semibold mt-6 "
                >
                  Submit Application
                </button>
              </form>
            </div>
            <div className=" rounded-xl p-3 px-6 bg-gray-50 text-sm text-gray-600 mt-8 flex items-top">
              <p className="mt-1">
                Need help? If you need any help, shoot us an email and the
                LocalTier Success team will reach out to you!
              </p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GetListed;
